<template lang="html">
  <div
    class="input-text"
    :class="{ 'input-text--error': isError, empty: model === '' }"
  >
    <div class="input-text__label" v-if="label">{{ label }}</div>
    <input
      type="text"
      :placeholder="placeholder"
      v-model="model"
      @focus="inputFocus"
    />
    <span class="input-text__error-message" v-if="isError && errorMessage">{{
      errorMessage
    }}</span>
    <SimpleKeyboard
      v-if="keyboardIsOpen"
      :placeholder="placeholder"
      :input="modelValue"
      :component-name="componentName"
      @close="keyboardClose"
      @onChange="onChange"
      @onKeyPress="onKeyPress"
    />
  </div>
</template>

<script>
import SimpleKeyboard from '@/components/SimpleKeyboard.vue';

export default {
  name: 'InputText',
  components: { SimpleKeyboard },
  props: {
    componentName: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    isError: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue', 'onKeyPress'],
  data() {
    return {
      uniqId: null,
    };
  },
  computed: {
    keyboardIsOpen() {
      return (
        this.$store.state.global.inHouse &&
        this.uniqId === this.$store.state.global.keyboardUniqID
      );
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  mounted() {
    this.uniqId = Math.random().toString(16).slice(2);
  },
  methods: {
    inputFocus() {
      this.$store.commit('global/changeInputFocus', this.uniqId);
    },
    onChange(input) {
      this.$emit('update:modelValue', input);
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);
    },
    keyboardClose() {
      this.$store.commit('global/changeInputFocus', null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
